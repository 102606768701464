/* eslint-disable no-console, class-methods-use-this */
/* global newrelic */
import SumoLogger from "sumo-logger";
import { v4 } from "uuid";
import Cookies from "js-cookie";

import { browserName, deviceType, osName } from "utils/deviceDetectUtils";

export const MOBILE_DEBUG_COOKIE_NAME = "mobileDebugOn";

export const mobileDebugOn = () => Cookies.get(MOBILE_DEBUG_COOKIE_NAME) === "true";

export const newRelic = (msg, err) => {
  if (typeof newrelic === "undefined") return;
  newrelic.noticeError(new Error(msg), err);
};

const DEFAULT_STYLE_LOGS = {
  margin: "0",
  "z-index": 999999,
  width: "100%",
  position: "relative",
  background: "white",
};

const getUserInfo = () => {
  if (!window.currentUser) return null;

  const userId = `User ID: ${window.currentUser.id}`;
  const isAnon = `isAnonymousUser: ${window.currentUser.role === "anonymous"}`;

  return `${userId} | ${isAnon}`;
};

const getBrowserInfo = () => {
  const device = deviceType === "browser" ? "desktop" : deviceType;
  const info = `Browser Name: ${browserName}, OS: ${osName}, Device: ${device}`;
  const cookieName = "sumo_logger_browser_id";
  let browserId = Cookies.get(cookieName);

  if (!browserId) {
    browserId = v4();
    Cookies.set(cookieName, browserId, { expires: 1825 });
  }

  return `Browser ID: ${browserId} | ${info}`;
};

const addTrackingInfo = (message) => {
  let trackingInfo = getBrowserInfo();
  const userInfo = getUserInfo();

  if (userInfo) {
    trackingInfo = `${trackingInfo} | ${userInfo}`;
  }

  return `Branch: ${process.env.GIT_BRANCH} | ${trackingInfo} | ${message}`;
};

const mobileDebug = (message) => {
  if (!mobileDebugOn()) return;

  const elem = document.createElement("p");
  Object.keys(DEFAULT_STYLE_LOGS).forEach((property) => {
    if (property != null) elem.style[property] = DEFAULT_STYLE_LOGS[property];
  });
  elem.innerHTML = message;
  document.body.appendChild(elem);
};

export class Logger {
  constructor() {
    if (!process.env.SUMOLOGGER_ENDPOINT) return null;

    const opts = {
      endpoint: process.env.SUMOLOGGER_ENDPOINT,
      sessionKey: v4(),
      returnPromise: false,
      interval: 20000, // Send messages in batches every 20 seconds
      batchSize: 100000, // Or when total log length reaches 100k charact
      sourceName: "JibJab ReactJS",
      sourceCategory: "jibjab_react_js",
      hostName: process.env.DEPLOY_ENV,
    };

    this.sumoLogger = new SumoLogger(opts);
  }

  sendToSummo(message, options) {
    if (!this.sumoLogger) {
      console.error(`No SumoLogger endpoint was provided - error msg intended to log: ${addTrackingInfo(message)}`);

      return;
    }

    const defaults = {
      url: window.location.href,
    };

    this.sumoLogger.log(addTrackingInfo(message), { ...defaults, ...options });
    mobileDebug(message);
  }

  log(message, options) {
    console.log(message);
    this.sendToSummo(message, options);
  }

  logError(message, options) {
    console.error(message);
    newRelic(message);
    this.sendToSummo(message, options);
  }

  logDebug(message) {
    console.debug(message);
    mobileDebug(message);
  }

  flushLogs() {
    if (!this.sumoLogger) return;

    this.sumoLogger.flushLogs();
  }
}

export const jjLogger = new Logger();
